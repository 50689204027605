<template>
  <div class="publicService">
    <div class="breadWrap">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>南繁管理</el-breadcrumb-item>
        <el-breadcrumb-item>重点项目</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title">
      <div class="tit">
        <span>{{content.projectTitle==null?'暂无标题':content.projectTitle==''?'暂无标题':content.projectTitle}}</span>
        <span v-if="content.parent !=='' && content.parent !== null">{{content.parent}}</span>
      </div>
      <p>{{content.startTime}}——{{content.endTime}}</p>
    </div>
    <div class="jobContent">
      <div></div>
      <div>工作内容</div>
      <div>
        <p ref="unfoldjob1">{{content.jobContent}}</p>
        <p class="unfold" @click="unfoldjob(1)" v-if="!show1"  ref="getheightone">
          [
          <span>展开</span>]
        </p>
        <p class="unfold" @click="unfoldjobs(1)" v-else>
          [
          <span>收回</span>]
        </p>
      </div>
    </div>
    <div class="jobContent schedule">
      <div></div>
      <div>进度统计</div>
      <div>
        <p ref="unfoldjob2">{{content.execution}}</p>
        <p class="unfold" @click="unfoldjob(2)" v-if="!show2"  ref="getheighttwo">
          [
          <span>展开</span>]
        </p>
        <p class="unfold" @click="unfoldjobs(2)" v-else>
          [
          <span>收回</span>]
        </p>
      </div>
    </div>
    <div class="table">
      <p>工程（工作）进度百分比</p>
      <p>资金落实（万元）</p>
      <p>资金支出（万元）</p>
      <p>占落实资金的百分比</p>
      <span>{{content.project}}</span>
      <span>{{content.fundImplementation}}</span>
      <span>{{content.capitalExpenditure}}</span>
      <span>{{content.percentage}}</span>
    </div>
    <div class="jobContent">
      <div></div>
      <div>下阶段工作计划</div>
      <div>
        <p ref="unfoldjob3">{{content.nextWorkPlan}}</p>
        <p class="unfold" @click="unfoldjob(3)" v-if="!show3" ref="getheightsur">
          [
          <span>展开</span>]
        </p>
        <p class="unfold" @click="unfoldjobs(3)" v-else>
          [
          <span>收回</span>]
        </p>
      </div>
    </div>
    <div class="jobContent schedule remark">
      <div></div>
      <div>备注</div>
      <div>
        <p ref="unfoldjob4">{{content.remarks}}</p>
        <p class="unfold" @click="unfoldjob(4)" v-if="!show4"  ref="getheightfor">
          [
          <span>展开</span>]
        </p>
        <p class="unfold" @click="unfoldjobs(4)" v-else>
          [
          <span>收回</span>]
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      content: {}
    };
  },
  created() {
    this.keyProgramAppById();
  },
  mounted() {
    this.$nextTick(() => {
      // 页面渲染完成后的回调
      if (this.$refs.getheightone.offsetHeight <= 90) {
        this.$refs.getheightone.style.display = 'none';
      } else {
        this.$refs.getheightone.style.display = 'block';
      }
      if (this.$refs.getheighttwo.offsetHeight <= 90) {
        this.$refs.getheighttwo.style.display = 'none';
      } else {
        this.$refs.getheighttwo.style.display = 'block';
      }
      if (this.$refs.getheightsur.offsetHeight <= 90) {
        this.$refs.getheightsur.style.display = 'none';
      } else {
        this.$refs.getheightsur.style.display = 'block';
      }
      if (this.$refs.getheightfor.offsetHeight <= 90) {
        this.$refs.getheightfor.style.display = 'none';
      } else {
        this.$refs.getheightfor.style.display = 'block';
      }
    });
  },
  methods: {
    unfoldjob(id) {
      if (id === 1) {
        this.show1 = true;
        this.$refs.unfoldjob1.style.WebkitLineClamp = "99";
      } else if (id === 2) {
        this.show2 = true;
        this.$refs.unfoldjob2.style.WebkitLineClamp = "99";
      } else if (id === 3) {
        this.show3 = true;
        this.$refs.unfoldjob3.style.WebkitLineClamp = "99";
      } else if (id === 4) {
        this.show4 = true;
        this.$refs.unfoldjob4.style.WebkitLineClamp = "99";
      }
    },
    async keyProgramAppById() {
      const api = this.$fetchApi.keyProgramAppById.api + this.$route.query.id;
      const data = await this.$fetchData.fetchPost(
        { id: this.$route.query.id },
        api,
        "json"
      );
      if (data.code === "200" && data.result) {
        this.content = data.result;
        console.log(data);
      }
    },
    unfoldjobs(id) {
      if (id === 1) {
        this.show1 = false;
        this.$refs.unfoldjob1.style.WebkitLineClamp = "3";
      } else if (id === 2) {
        this.show2 = false;
        this.$refs.unfoldjob2.style.WebkitLineClamp = "3";
      } else if (id === 3) {
        this.show3 = false;
        this.$refs.unfoldjob3.style.WebkitLineClamp = "3";
      } else if (id === 4) {
        this.show4 = false;
        this.$refs.unfoldjob4.style.WebkitLineClamp = "3";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.publicService {
  font-size: 16px;
  line-height: 16px;
  color: #333;
  .breadWrap {
    border-bottom: 1px solid #ccc;
    .el-breadcrumb {
      width: 1200px;
      margin: 20px auto;
      font-size: 16px;
    }
  }
}
.title {
  width: 1181px;
  margin: 39px auto;
  padding-bottom: 30px;
  border-bottom: 1px solid #d9d9d9;
  .tit {
    text-align: center;
    line-height: 30px;
    position: relative;
    span:nth-child(1) {
      font-family: MicrosoftYaHei-Bold;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 1px;
      color: #333333;
    }
    span:nth-child(2) {
      position: absolute;
      top: 20%;
      display: inline-block;
      border-radius: 4px;
      border: 1px solid #009944;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 1px;
      color: #009944;
      text-align: center;
      padding: 3px 6px;
      margin-left: 10px;
    }
  }
  p {
    text-align: center;
    font-family: ArialMT;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 1px;
    color: #5c5c5c;
  }
}
.jobContent {
  width: 1181px;
  margin: 2px auto;
  div:nth-child(1) {
    width: 6px;
    height: 20px;
    background-color: #265ae1;
    float: left;
    margin-right: 10px;
  }
  div:nth-chile(2) {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
  }
  div:nth-child(3) {
    margin-top: 20px;
    position: relative;
    p {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #333333;
      text-indent: 33px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .unfold {
      position: absolute;
      right: 0;
    }
    span {
      color: #265ae1;
    }
  }
}
.schedule {
  margin-top: 41px;
  padding-bottom: 35px;
}
.table {
  margin: auto;
  width: 920px;
  height: 120px;
  background-color: #d5e5ff;
  margin-bottom: 40px;
  p {
    display: inline-block;
    background: #4489fc;
    padding: 10px 20px 10px 20px;
    border-right: 1px solid #fff;
    font-family: MicrosoftYaHei;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 1px;
    color: #ffffff;
  }
  span {
    display: inline-block;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-right: 1px solid #fff;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #4489fc;
  }
  span:nth-child(5) {
    width: 271px;
  }
  span:nth-child(6) {
    width: 208px;
  }
  span:nth-child(7) {
    width: 208px;
  }
  span:nth-child(8) {
    width: 229px;
  }
}
.remark {
  margin-bottom: 90px;
}
</style>